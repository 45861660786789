import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

LongLogo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function LongLogo({ disabledLink = false, sx }) {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR
    // const logo = '/logo/logo_single.svg';

    const logo = (
        <Box sx={{  marginRight:"1em", ...sx }}>
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 279.70001 59.330002"
                width="148"
                height="30"
                xmlns="http://www.w3.org/2000/svg"><defs
   id="defs240" />



                <g
                    id="g143"
                    transform="translate(-1610.62,-776.77)">
	<g
        id="g141">
		<g
            id="g135">
			<path
                className="st9"
                d="m 1632.37,778.69 v 9.84 h 16.87 v 22.22 c 0,8.48 -4.47,15.12 -14.07,15.12 -9.51,0 -14.15,-4.88 -14.15,-14.48 h -10.4 c 0,15.83 10.24,24.39 24.55,24.39 15.43,0 24.62,-10.55 24.62,-25.03 v -32.06 z"
                id="path125"/>
            <path
                className="st9"
                d="m 1716.02,814.58 c -3.19,0 -6.36,0.3 -9.42,0.96 -2.48,5.15 -7.47,8.96 -15.62,8.96 h -11.51 v -35.82 h 11.51 c 8.15,0 13.14,3.92 15.61,9.14 3.11,0.7 6.35,1.03 9.61,1.03 h 1.83 c -2.7,-10.95 -11.29,-20.16 -27.04,-20.16 H 1669 v 55.97 h 21.99 c 15.68,0 24.34,-9.16 27.05,-20.07 h -2.02 z"
                id="path127"/>
            <path
                className="st9"
                fill="%23121227"
                d="m 1753.03,777.57 c -15.43,0 -24.55,9.4 -27.62,20.68 4.97,-0.89 9.75,-2.76 14.11,-5.46 2.98,-3.36 7.42,-5.62 13.5,-5.62 13.19,0 18.47,10.55 18.31,19.98 -0.16,9.2 -5.11,19.11 -18.31,19.11 -6.29,0 -10.7,-2.23 -13.63,-5.57 -4.42,-2.7 -9.2,-4.6 -14.18,-5.51 2.82,11.4 11.84,20.75 27.82,20.75 19.67,0 28.54,-14.47 28.7,-28.94 0.16,-14.62 -9.44,-29.42 -28.7,-29.42 z"
                id="path129"/>
            <path
                className="st9"
                d="m 1831.62,820.35 c -3.68,3.68 -9.2,5.68 -14.07,5.68 -13.83,0 -19.19,-9.68 -19.27,-18.95 -0.08,-9.35 5.76,-19.43 19.27,-19.43 4.87,0 9.83,1.68 13.51,5.28 l 7.04,-6.8 c -5.76,-5.68 -12.95,-8.55 -20.55,-8.55 -20.23,0 -29.74,14.87 -29.67,29.5 0.08,14.55 8.88,28.86 29.67,28.86 8.07,0 15.51,-2.64 21.27,-8.31 z"
                id="path131"/>
            <path
                className="st9"
                d="m 1867.77,800.44 c -6.72,-0.8 -12.48,-2.16 -12.48,-7.04 0,-4.16 3.76,-7.43 11.59,-7.43 6.08,0 11.35,3.03 13.28,6.23 l 8.4,-4.32 c -4.24,-8.64 -13.28,-11.11 -21.99,-11.11 -10.32,0.08 -21.67,4.8 -21.67,16.31 0,12.55 10.55,15.59 21.99,16.95 7.44,0.8 12.95,2.96 12.95,8.24 0,6.07 -6.24,8.4 -12.87,8.4 -6.8,0 -13.28,-2.72 -15.75,-8.88 l -8.8,4.56 c 4.16,10.24 12.95,13.75 24.39,13.75 12.47,0 23.51,-5.36 23.51,-17.83 -0.01,-13.35 -10.88,-16.39 -22.55,-17.83 z"
                id="path133"/>
		</g>
        <g
            id="g139">
			<path
                className="st10"
                fill="#43B5E7"
                d="m 1753.02,791.16 c -3.6,0 -6.48,1 -8.69,2.61 -7.47,5.43 -16.36,8.57 -25.6,8.57 h -2.53 c -6.64,0 -13.3,-1.26 -19.23,-4.25 -1.25,-0.63 -2.76,-0.99 -4.57,-0.99 h -6.1 v 18.99 h 6.1 c 1.78,0 3.27,-0.34 4.51,-0.94 5.93,-2.86 12.53,-4.06 19.12,-4.06 h 2.54 c 9.28,0 18.15,3.24 25.7,8.65 2.19,1.57 5.07,2.54 8.76,2.54 10.5,0 14.44,-7.89 14.57,-15.21 0.11,-7.51 -4.08,-15.91 -14.58,-15.91 z"
                id="path137"/>
		</g>
	</g>
</g>




</svg>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
