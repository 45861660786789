// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  case: getIcon('ic_case'),
  blog: getIcon('ic_blog'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  analytics: getIcon('ic_analytics'),

  help: getIcon('ic_analytics'),
  gear: getIcon('ic_analytics'),

  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.jdocs.home, icon: ICONS.dashboard },
      { title: 'Cases', path: PATH_DASHBOARD.jdocs.cases, icon: ICONS.case},
      { title: 'Queues', path: PATH_DASHBOARD.jdocs.queues, icon: ICONS.booking },
      { title: 'Members', path: PATH_DASHBOARD.jdocs.members, icon: ICONS.user },
      { title: 'File Upload', path: PATH_DASHBOARD.jdocs.upload, icon: ICONS.invoice },
      { title: 'File Review', path: PATH_DASHBOARD.jdocs.review, icon: ICONS.analytics },
    ],
  },

  // SECONDARY
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // USER
      { title: 'Settings', path: PATH_DASHBOARD.jdocs.settings, icon: ICONS.help },
      { title: 'Help Center', path: PATH_DASHBOARD.jdocs.help, icon: ICONS.gear },
    ],
  },
];

export default navConfig;
