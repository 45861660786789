import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/reference')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <HomePage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <HomePage /> },
        { path: 'register-unprotected', element: <HomePage /> },
        { path: 'reset-password', element: <HomePage /> },
        { path: 'new-password', element: <HomePage /> },
        { path: 'verify', element: <HomePage /> },
      ],
    },
    // JDocs Routes
    {
      path: 'app',
      element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <JDocsDashboard /> },
        { path: 'cases', element: <Cases /> },
        { path: 'case/:id', element: <Case /> },

        { path: 'queues', element: <Queues /> },
        { path: 'queue/:id', element: <Queue /> },

        { path: 'members', element: <Members /> },
        { path: 'member/:id', element: <Member/> },

        { path: 'review', element: <FileReview /> },
        { path: 'upload', element: <FileUpload /> },
        { path: 'settings', element: <Settings /> },
        { path: 'help', element: <Help /> },
        { path: 'file/:id', element: <File /> },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'flex-file', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// JDOCS
const JDocsDashboard = Loadable(lazy(() => import('../pages/jdocs/app')));

const Cases = Loadable(lazy(() => import('../pages/jdocs/Cases')));
const Case = Loadable(lazy(() => import('../pages/jdocs/Case')));

const Queues = Loadable(lazy(() => import('../pages/jdocs/Queues')));
const Queue = Loadable(lazy(() => import('../pages/jdocs/Queue')));

const Members = Loadable(lazy(() => import('../pages/jdocs/Members')));
const Member = Loadable(lazy(() => import('../pages/jdocs/Member')));

const FileUpload = Loadable(lazy(() => import('../pages/jdocs/FileUpload')));
const FileReview = Loadable(lazy(() => import('../pages/jdocs/FileReview')));

const File = Loadable(lazy(() => import('../pages/jdocs/File')));

const Settings = Loadable(lazy(() => import('../pages/jdocs/Settings')));
const Help = Loadable(lazy(() => import('../pages/jdocs/Help')));



// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/reference/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/reference/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/reference/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/reference/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/reference/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/reference/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/reference/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/reference/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/reference/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/reference/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/reference/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/reference/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/reference/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/reference/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/reference/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/reference/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/reference/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/reference/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/reference/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/reference/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/reference/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/reference/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/reference/Chat')));
const Mail = Loadable(lazy(() => import('../pages/reference/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/reference/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/reference/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/reference/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
