import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import {Avatar, Typography, ListItemText, ListItemAvatar, MenuItem, Button} from '@mui/material';
// utils
import {green, grey} from "@mui/material/colors";
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { _contacts } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function CaseSelectPopover() {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <Button onClick={handleOpen} style={{
                borderColor: "#E5E5E5",
                border: '2px solid',
                borderRadius: '16 px',
                marginLeft: "1%",
                minWidth:"158px",
            }} >

                <Iconify icon={'akar-icons:circle-fill'} sx={{color: "#1D9509",}} width={10} height={10} />
                John Doe vs. Apple County
                <Iconify icon={'akar-icons:arrow-down'} width={20} height={20} />

            </Button>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 320,
                    '& .MuiMenuItem-root': {
                        px: 1.5,
                        height: ITEM_HEIGHT,
                        borderRadius: 0.75,
                    },
                }}
            >
                <Typography variant="h6" sx={{ p: 1.5 }}>
                    Cases <Typography component="span">({_contacts.length})</Typography>
                </Typography>

                <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
                    {_contacts.map((contact) => (
                        <MenuItem key={contact.id}>
                            <ListItemAvatar sx={{ position: 'relative' }}>
                                <Avatar src={contact.avatar} />
                                <BadgeStatus status={contact.status} sx={{ position: 'absolute', right: 1, bottom: 1 }} />
                            </ListItemAvatar>

                            <ListItemText
                                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                                secondaryTypographyProps={{ typography: 'caption' }}
                                primary={contact.name}
                                secondary={contact.status === 'offline' && fToNow(contact.lastActivity)}
                            />
                        </MenuItem>
                    ))}
                </Scrollbar>
            </MenuPopover>
        </>
    );
}
