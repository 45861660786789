import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: ['JavaScript version', 'TypeScript version', 'Design Resources', 'Commercial applications'],
  icons: [
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'basic',
    icon: <PlanFreeIcon />,
    price: 0,
    caption: 'For Basic File Review',
    lists: [
      { text: 'Up to 1 GB uploaded', isAvailable: true },
      { text: '24/7/365 in-app support', isAvailable: true },
      { text: 'Up to 1 user', isAvailable: false },
      { text: 'Dupe detection', isAvailable: false },
      { text: '1 GB uploaded free', isAvailable: false },
    ],
    labelAction: 'included at sign-up',
  },
  {
    subscription: 'starter',
    icon: <PlanStarterIcon />,
    price: 200,
    caption: 'Per matter per month',
    lists: [
      { text: 'Unlimited uploading', isAvailable: true },
      { text: '24/7/365 in-app support', isAvailable: true },
      { text: 'Unlimited users', isAvailable: true },
      { text: 'Dupe detection', isAvailable: false },
      { text: '$5 per GB uploaded', isAvailable: true },
    ],
    labelAction: 'choose starter',
  },
  {
    subscription: 'plus',
    icon: <PlanStarterIcon />,
    price: 250,
    caption: 'Per matter per month',
    lists: [
      { text: 'Unlimited uploading', isAvailable: true },
      { text: '24/7/365 in-app support', isAvailable: true },
      { text: 'Unlimited users', isAvailable: true },
      { text: 'Dupe detection', isAvailable: true },
      { text: '$5 per GB uploaded', isAvailable: true },
    ],
    labelAction: 'choose starter',
  }
  ];
